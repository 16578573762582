
import React, { useState,useEffect,useCallback } from "react";
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';
import BtnCellRenderer from "./BtnCellRenderer";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import  RowGroupingModule  from 'ag-grid-community';
import ScaleLoader from "react-spinners/ScaleLoader";

const ContentList=(props)=>{

    const [contentList,setContentList]=useState([]);
    const [isLoading,setLoading]=useState(false);

      ///----ag grid start----//
      const [gridApi,setGridApi]=useState()
      const columnDefs = [
        //{ headerName: "ID", field: "id",checkboxSelection:true,headerCheckboxSelection:true,},
        { headerName: "Name", field: "videoname",},
        { headerName: "Group", field: "groupName",},
        { headerName: "Content Type", field: "content_type",},
        { headerName: "Category", field: "categoryName", },
        { headerName: "Action", 
        cellRenderer: "BtnCellRenderer"
          },
      ]  
  
      const defaultColDef = {
        sortable: true,
        editable: true,
        flex: 1, filter: true,
        floatingFilter: true, 
      }
    
      const onGridReady = (params) => {
        setGridApi(params.api);
       // gridApi.showLoadingOverlay();
        loadContentList();
        params.api.paginationGoToPage(10);
       
      };
    
      const onPageSizeChanged = (newPageSize) => {
        gridApi.paginationSetPageSize(Number(newPageSize));
      };
      //---------------ag grid end----//
  
    // useEffect(() => {
    //     loadContentList();
    // },[]);

    const loadContentList=useCallback(()=>{
        setLoading(false);
        Axios.post(props.apiUrlPrefix + "/getContentsAdmin",null,props.apiHeaderRequest)
        .then((res) => {
            if(res!=null && res.data!=null && res.data.statusCode===200 && res.data.body.length>0 ){
                var lst = res.data.body;
                lst.map(data=>{
                    data.categoryName=data.grp!=null && data.grp.cat!=null?data.grp.cat.name:'';
                    data.groupName=data.grp!=null? data.grp.name:''
                })
                setContentList(lst);
               // gridApi.hideOverlay();
            }
            setLoading(false);
        })
        .catch(err => {  
            console.log(err) ;
            setLoading(false);
        });
    },[])

    const btnDeleteContent=(id)=>{
        setLoading(true);
        const params ={
            contentId:id
        }
        Axios.post(props.apiUrlPrefix + "/archiveContent",params,props.apiHeaderRequest)
        .then((res) => {
            if(res!=null && res.data!=null && res.data.statusCode===200 && res.data.body.id>0 ){
                alert('Content has been archived successfully.');
                loadContentList();
            }
            setLoading(false);
        })
        .catch(err => {  
            console.log(err) ;
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <div className={isLoading?'loader-style':''}  >
        <ScaleLoader
          size={150}
          color={"white"}
          loading={isLoading}
        />
      </div>
        <div className="content-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 ">
                        <h2>Content List</h2>
                    </div>
                </div>
            </div>
        </div>
        <div className="content">
            <div className="content-fluid">
<div className="card">

</div>
<div className="card">
<div className="card-body">
<div className="row">
<div className="col-md-12">
<span className="pull-left">Page size: <select onChange={(e)=>onPageSizeChanged(e.target.value)}>
    <option value='10'>10</option>
    <option value='25'>25</option>
    <option value='50'>50</option>
    <option value='100'>100</option>
  </select>
</span>
<a href="/content"><span className="btn btn-sm btn-info float-right "    >+ Create Content</span></a>
</div>
<div className="col-md-12 ag-theme-alpine " >
    <AgGridReact
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      pagination={true}
      context={{ btnDeleteContent }}
      paginationPageSize={10}
      frameworkComponents={{ BtnCellRenderer }}
      rowData={contentList}
      onGridReady={onGridReady}
      gridOptions={{}}
      colResizeDefault={"shift"}
      domLayout={'autoHeight'}
      animateRows={true}
      pivotPanelShow="always"
      rowSelection="multiple"
    
      >
    </AgGridReact>
</div>
</div>

 
</div>
</div>
            </div>
        </div>
    </React.Fragment>
    
    )
}
export default ContentList