import React, { useState, useEffect, useCallback } from "react";
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';
import './contentStyle.css'
import { Input } from "reactstrap"
import { Button, FormGroup, FormControl, Form } from "react-bootstrap";
import ReactS3 from 'react-s3'
import ScaleLoader from "react-spinners/ScaleLoader";
import Config from '../../config'
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";

const videoConfig = {
  bucketName: Config.bucketName,//'dev-s3-tabnu-training-client',
  dirName: 'video', /* optional */
  region: 'eu-west-1',
  accessKeyId: Config.accessKey,
  secretAccessKey: Config.secretAccessKey
}

const documentConfig = {
  bucketName: Config.bucketName,//'dev-s3-tabnu-training-client',
  dirName: 'attachment', /* optional */
  region: 'eu-west-1',
  accessKeyId: Config.accessKey,
  secretAccessKey: Config.secretAccessKey
}

const thumbnailConfig = {
  bucketName: Config.bucketName,//'dev-s3-tabnu-training-client',
  dirName: 'thumbnail', /* optional */
  region: 'eu-west-1',
  accessKeyId: Config.accessKey,
  secretAccessKey: Config.secretAccessKey
}

const Content = (props) => {
  const [content, setContent] = useState({
    id: 0,
    videolength: 0,
    status: true,
    videoname: '',
    description: '',
    metadata: '',
    category: 0,
    group: 0,
    filename: '',
    attachment_description: '',
    thumbnailkey: '',
    attachmentkey: '',
    sequence: 0,
    version: 0,
    content_type: 'video',
    //status:true,
    sortOrder: 'auto',
    supplimentary: true,
    languageId: 1,
    isfeatured: false,
    typeId: 0
  });
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [groups, setGroups] = useState([]);
  const [validation, setValidation] = useState({ validationId: 0, validationMessage: '' });
  const [isLoading, setLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [thumbnailImage, setThumbnailImage] = useState('');
  const [supplimentaryAttachment, setSupplimentaryAttachment] = useState('');

  const loadLanguage = async () => {
    setLoading(true);
    await Axios.post(props.apiUrlPrefix + "/getLanguages")
      .then((res) => {
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
          let list = res.data.body;
          setLanguages(list);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const getLanguages = async () => {
    let lst = [];
    setLoading(true);
    await Axios.post(props.apiUrlPrefix + "/getLanguages")
      .then((res) => {
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
          lst = res.data.body;
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
    return lst;
  };

  const loadTypes = async () => {
    setLoading(true);
    await Axios.post(props.apiUrlPrefix + "/getTypesAdmin", null, props.apiHeaderRequest)
      .then((res) => {
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
          let typeList = res.data.body;
          setTypes(typeList);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const loadCategories = async () => {
    setLoading(true);
    await Axios.post(props.apiUrlPrefix + "/getCategoriesAdmin", null, props.apiHeaderRequest)
      .then((res) => {
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
          let categoryList = res.data.body;
          setCategories(categoryList);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadGroups = useCallback(async (objCategoryId, objTypeId) => {
    const params = {
      categoryId: objCategoryId,
      userId: props.userId,
    };
    setLoading(true);
    Axios.post(props.apiUrlPrefix + "/getGroupsAdmin?", params, props.apiHeaderRequest)
      .then((res) => {
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
          if (parseInt(objCategoryId) > 0 && parseInt(objTypeId) > 0) {
            let lstGroups = res.data.body.filter(x => x.category === parseInt(objCategoryId) && x.type_id === parseInt(objTypeId));
            setGroups(lstGroups);
          }
          else {
            setGroups([]);
          }
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const loadContent = useCallback((videoId, groupId) => {
    setLoading(true);
    const params = {
      groupId: groupId,
      videoId: videoId
    }
    Axios.post(props.apiUrlPrefix + "/getContentForEditAdmin", params, props.apiHeaderRequest)
      .then(async (res) => {
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
          var obj = res.data.body[0];
          console.log("category: " + obj.grp.category + " typeId: " + obj.grp.type_id);
          await loadGroups(obj.grp.category, obj.grp.type_id);
          const editContent = {
            id: obj.id,
            videolength: obj.videolength,
            status: obj.status,
            videoname: obj.videoname,
            description: obj.description,
            metadata: obj.metadata,
            //category: obj.category,
            group: obj.group,
            supplementary: obj.filename != null && obj.filename.length > 0 ? true : false,
            filename: obj.filename != null && obj.filename.length > 0 ? obj.filename : '',
            documentDescription: '',
            thumbnailkey: obj.thumbnailkey != null && obj.thumbnailkey.length > 0 ? obj.thumbnailkey : '',
            attachmentkey: obj.attachmentkey != null && obj.attachmentkey.length > 0 ? obj.attachmentkey : '',
            sequence: obj.sequence,
            attachment_description: obj.attachment_description,
            version: obj.version,
            content_type: obj.content_type.toLowerCase(),
            sortOrder: 'specify',//obj.isAutoSequence==='auto'?'auto':'specify',
            supplimentary: obj.filename != null && obj.filename.length > 0 ? true : false,
            languageId: 1,
            isfeatured: obj.isfeatured,
            category: obj.grp != null ? obj.grp.category : 0,
            typeId: obj.grp != null ? obj.grp.type_id : 0
          }
          if (obj.vidlng && obj.vidlng.length > 0) {
            var lst = videoList;
            const languageList = await getLanguages();
            obj.vidlng.map(async data => {
              const lang = languageList.find(x => x.id === parseInt(data.language));
              lst.push({
                id: data.id,
                video: data.video,
                language: data.language,
                videokey: data.videokey,
                isdelete: data.isdelete,
                videolength: data.videolength ?? 0,
                //languageName:lang!=null?lang.name:'',
                group: parseInt(data.group)
              });
            })
            setVideoList(lst);
          }

          setContent(editContent);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, [])

  const handleChange = input => ({ target: { value } }) => {
    setContent({
      ...content,
      [input]: value
    });
    if (input === 'category') {
      console.log("handleChange category: " + parseInt(value) + " typeId: " + content.typeId);
      loadGroups(parseInt(value), content.typeId);
    }
    if (input === 'typeId') {
      console.log("handleChange typeId: " + parseInt(value) + " category: " + content.category);
      loadGroups(content.category, parseInt(value));
    }
  }

  const handleRadioChange = input => ({ target: { value } }) => {

    setContent({
      ...content,
      [input]: value == "true" ? true : false
    })
  }

  const removeVideoContent = (id) => {
    let videos = [];
    videoList.map(data => {
      if (data.id === id) {
        data.isdelete = true;
      }
      videos.push(data);
    });
    setVideoList(videos);
  }

  const uploadVideo = event => {
    console.log('a');
    var file = event.target.files[0];
    //console.log(file);

    var duration = 0;

    //event.currentTarget.duration;
    var selectedLanguage = languages.find(x => x.id === parseInt(content.languageId));
    var selectedList = videoList.filter(x => x.language === selectedLanguage.id && !x.isdelete);
    if (selectedList.length > 0) {
      alert('video for this language already exists');
      return false;
    }
    setLoading(true);
    console.log('b');
    const target = { Bucket: videoConfig.bucketName, Key: videoConfig.dirName + '/' + file.name, Body: file };
    const creds = {
      accessKeyId: videoConfig.accessKeyId,
      secretAccessKey: videoConfig.secretAccessKey
    }
    try {
      console.log('c');
      console.log(file);
      var parallelupload = new Upload({
        client: new S3Client({ region: videoConfig.region, credentials: creds }),
        leavePartOnError: false,
        params: target,
      })
      parallelupload.on("httpUploadProgress", ((progress, index) => {
        console.log(progress);
        if (progress.loaded === progress.total) {
          console.log('done');
          var lst = videoList;
          lst.push({
            id: 0,
            video: 0,
            videolength: content.content_type === 'video' ? Math.round(duration ?? 0) : 0,
            language: selectedLanguage.id,
            videokey: progress.Key,//.replace(videoConfig.dirName + '/', ''),
            isdelete: false,
            //languageName:selectedLanguage.name,
            group: parseInt(content.group)
          });
          setVideoList(lst);
          setLoading(false);

        }
      }))
      parallelupload.done();
    }
    catch (e) {
      console.log(e);
      setLoading(false);
    }

    // ReactS3.uploadFile(file, videoConfig)
    //   .then(data => {
    //     //alert('123');
    //     console.log(data);
    //     var lst = videoList;
    //     lst.push({
    //       id: 0,
    //       video: 0,
    //       videolength: content.content_type === 'video' ? Math.round(duration ?? 0) : 0,
    //       language: selectedLanguage.id,
    //       videokey: data.key,
    //       isdelete: false,
    //       //languageName:selectedLanguage.name,
    //       group: parseInt(content.group)
    //     });
    //     setVideoList(lst);
    //     setLoading(false);
    //   })
    //   .catch(err => {
    //     console.error(err);
    //     setLoading(false);

    //   })
  }

  const uploadDocument = event => {
    var file = event.target.files[0];
    console.log(file);
    setLoading(true);

    const target = { Bucket: documentConfig.bucketName, Key: documentConfig.dirName + '/' + file.name, Body: file };
    const creds = {
      accessKeyId: documentConfig.accessKeyId,
      secretAccessKey: documentConfig.secretAccessKey
    }
    try {
      console.log(file);
      var parallelupload = new Upload({
        client: new S3Client({ region: documentConfig.region, credentials: creds }),
        leavePartOnError: false,
        params: target,
      })
      parallelupload.on("httpUploadProgress", ((progress, index) => {
        console.log(progress);
        if (progress.loaded === progress.total) {
          console.log('done');
          let obj = content;
          obj.attachmentkey = progress.Key;//.replace(documentConfig.dirName + '/', '');
          setContent(obj);
          setLoading(false);
        }
      }))
      parallelupload.done();
    }
    catch (e) {
      console.log(e);
      setLoading(false);
    }

    // ReactS3.uploadFile(file, documentConfig)
    //   .then(data => {
    //     console.log(data);
    //     let obj = content;
    //     obj.attachmentkey = data.key;
    //     setContent(obj);
    //     setLoading(false);
    //   })
    //   .catch(err => {
    //     console.error(err);
    //     setLoading(false);
    //   })
  }

  const uploadThumbnail = event => {
    setLoading(true);
    var file = event.target.files[0];
    console.log(file);
    
    const target = { Bucket: thumbnailConfig.bucketName, Key: thumbnailConfig.dirName + '/' + file.name, Body: file };
    const creds = {
      accessKeyId: thumbnailConfig.accessKeyId,
      secretAccessKey: thumbnailConfig.secretAccessKey
    }
    try {
      console.log(file);
      var parallelupload = new Upload({
        client: new S3Client({ region: thumbnailConfig.region, credentials: creds }),
        leavePartOnError: false,
        params: target,
      })
      parallelupload.on("httpUploadProgress", ((progress, index) => {
        console.log(progress);
        if (progress.loaded === progress.total) {
          console.log('done');
          let obj = content;
          obj.thumbnailkey = progress.Key;//.replace(thumbnailConfig.dirName + '/', '');
          setContent(obj);
          setLoading(false);
        }
      }))
      parallelupload.done();
    }
    catch (e) {
      console.log(e);
      setLoading(false);
    }

    // ReactS3.uploadFile(file, thumbnailConfig)
    //   .then(data => {
    //     console.log(data);
    //     let obj = content;
    //     obj.thumbnailkey = data.key;
    //     setContent(obj);
    //     setLoading(false);
    //   })
    //   .catch(err => {
    //     console.error(err);
    //     setLoading(false);
    //   })
  }

  const validateForm = () => {
    setValidation({ validationId: 0, validationMessage: '' });
    if (content.videoname.length <= 0) {
      setValidation({ validationId: 1, validationMessage: 'Enter the content name' });
      return false;
    }
    else if (content.description.length <= 0) {
      setValidation({ validationId: 4, validationMessage: 'Enter the description' });
      return false;
    }
    // else  if(content.content_type==='video' && content.videolength<=0){
    //   setValidation({validationId:6,validationMessage:'Enter the video length value'});
    //   return false;
    // }
    else if (content.category <= 0) {
      setValidation({ validationId: 2, validationMessage: 'Please select category' });
      return false;
    }
    else if (content.group <= 0) {
      setValidation({ validationId: 3, validationMessage: 'Please select group' });
      return false;
    }
    else if (videoList.length <= 0) {
      setValidation({ validationId: 5, validationMessage: 'Please upload the content file' });
      return false;
    }
    else if (content.thumbnailkey.length <= 0) {
      setValidation({ validationId: 4, validationMessage: 'Please upload thumbnail image' });
      return false;
    }
    else if (content.attachmentkey.length > 0 && content.filename.length <= 0) {
      setValidation({ validationId: 4, validationMessage: 'Enter the suplimentary ocument name' });
      return false;
    }
    else if (content.attachmentkey.length <= 0 && content.filename.length > 0) {
      setValidation({ validationId: 4, validationMessage: 'Please upload attachment for supplimentary document' });
      return false;
    }
    else if (content.typeId <= 0) {
      setValidation({ validationId: 6, validationMessage: 'Please select type' });
      return false;
    }
    return true;
  }

  const handleSubmit = async event => {
    event.preventDefault()
    if (isLoading) { return; }

    const validationStatus = validateForm();
    if (!validationStatus) {
      return false;
    }
    try {
      setLoading(true);

      if (content.id > 0) {
        const params = {
          groupId: parseInt(content.group),
          videoId: parseInt(content.id),
          videoName: content.videoname,
          sequence: parseInt(content.sequence),
          status: content.status,
          description: content.description,
          metadata: content.metadata,
          categoryId: parseInt(content.category),
          fileName: content.filename,
          attachmentKey: content.attachmentkey,
          thumbnailKey: content.thumbnailkey,
          version: 0,
          contentType: content.content_type,
          isAutoSequence: content.sortOrder === "auto",
          attachmentDescription: content.attachment_description,
          videoLanguages: videoList,
          isfeatured: content.isfeatured
        }
        console.log('Request: ' + JSON.stringify(params));
        const res = await Axios.post(props.apiUrlPrefix + "/updateContentAdmin", params, props.apiHeaderRequest);
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.id > 0) {
          alert("Information Saved");
          setLoading(false);
          props.history.push('/contentlist');
        }
        else {
          setLoading(false);
        }
      }
      else {
        const params =
        {
          status: content.status,
          videoName: content.videoname,
          description: content.description,
          metadata: content.metadata,
          categoryId: parseInt(content.category),
          groupId: parseInt(content.group),
          fileName: content.filename,
          attachmentKey: content.attachmentkey,
          thumbnailKey: content.thumbnailkey,
          sequence: parseInt(content.sequence),
          version: 0,
          contentType: content.content_type,
          attachmentDescription: content.attachment_description,
          isAutoSequence: content.sortOrder === "auto",
          videoLanguages: videoList,
          isfeatured: content.isfeatured
        }
        console.log('Request: ' + JSON.stringify(params));
        const res = await Axios.post(props.apiUrlPrefix + "/createContent", params, props.apiHeaderRequest);
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.id > 0) {
          alert("Information Saved");
          props.history.push('/contentlist');
          setLoading(false);
        }
        else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  }

  useEffect(async () => {
    await loadLanguage();
    await loadTypes();
    await loadCategories();

    const videoId = parseInt(props.match.params.videoId);
    const groupId = parseInt(props.match.params.groupId);
    if (videoId > 0) {
      await loadContent(videoId, groupId);
    }
  }, []);

  return (
    <React.Fragment>
      <div className={isLoading ? 'loader-style' : ''} >
        <ScaleLoader
          size={150}
          color={"white"}
          loading={isLoading}
        />
      </div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 ">
              <h2>Content <small>create/update</small></h2>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="content-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <form onSubmit={handleSubmit} className="content-form row">
                    <div className="col-md-4">
                      <FormGroup controlId="content.id" className="hide-control" >
                        <div className="">
                          <FormControl
                            autoFocus
                            type="hidden"
                            placeholder="Email"
                            value={content.id} onChange={handleChange('id')}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup controlId="content.TypeId" >
                        <div className="content-input-group">
                          <span className="content-text-heads">
                            Select Type:
                          </span>
                          <Input
                            type="select"
                            id="data-typeId"
                            value={content.typeId}
                            className="content-input-field content-dropdown"
                            onChange={handleChange('typeId')}
                          >
                            <option value="0">Select Type</option>
                            {
                              types && types.map((data, index) => (
                                <option value={data.id} key={index}>{data.name}</option>
                              ))
                            }
                          </Input>
                        </div>
                      </FormGroup>
                      <FormGroup controlId="content.Category" >
                        <div className="content-input-group">
                          <span className="content-text-heads">
                            Select Category:
                          </span>
                          <Input
                            type="select"
                            id="data-category"
                            value={content.category}
                            className="content-input-field content-dropdown"
                            onChange={handleChange('category')}
                          >
                            <option value="0">Select Category</option>
                            {
                              categories && categories.map((data, index) => (
                                <option value={data.id} key={index}>{data.name}</option>
                              ))
                            }
                          </Input>
                        </div>
                      </FormGroup>
                      <FormGroup controlId="content.content_type " >
                        <div className="content-input-group">
                          <span className="content-text-heads">
                            Content Type:
                          </span>
                          <br></br>
                          <input type="radio" name="contentType" className="ml-5"
                            value="video"
                            checked={content.content_type === "video"}
                            onChange={handleChange('content_type')}
                          /> Video
                          <input type="radio" name="contentType" className="ml-5"
                            value="document"
                            checked={content.content_type === "document"}
                            onChange={handleChange('content_type')}
                          />
                          Document
                        </div>
                      </FormGroup>

                      <FormGroup controlId="content.group" >
                        <div className="content-input-group">
                          <span className="content-text-heads">
                            Select Group:
                          </span>
                          <Input
                            type="select"
                            id="data-Institute"
                            value={content.group}
                            className="content-input-field content-dropdown"
                            onChange={handleChange('group')}
                          >
                            <option value="0">Select Group</option>
                            {
                              groups && groups.map((data, index) => (
                                <option value={data.id} key={index}>{data.name}</option>
                              ))
                            }

                          </Input>
                        </div>
                      </FormGroup>
                      <FormGroup controlId="content.sortOrder " >
                        <div className="content-input-group">
                          <span className="content-text-heads">
                            Sort Order:
                          </span>
                          <br></br>
                          <input type="radio" name="sortOrder" className="ml-5"
                            value="auto"
                            checked={content.sortOrder === "auto"}
                            onChange={handleChange('sortOrder')}
                          />
                          Auto
                          <input type="radio" name="sortOrder" className="ml-5"
                            value="specify"
                            checked={content.sortOrder === "specify"}
                            onChange={handleChange('sortOrder')}
                          />
                          Specify:
                          <input
                            type="text"
                            disabled={content.sortOrder === "auto"}
                            value={content.sequence}
                            className="content-input-field form-control order-input"
                            onChange={handleChange('sequence')}
                          />
                        </div>
                      </FormGroup>
                      <p>Specifying the sort order of an existing item
                        for this content will move the existing and
                        sequential value. Auto will assign a sort order of the next
                        each following item down in order by one. </p>
                      <FormGroup controlId="content.status" >
                        <div className="content-input-group">
                          <span className="content-text-heads">
                            Status:
                          </span>
                          <br />
                          <input type="radio" name="contentStatus" className="ml-5"
                            value={true}
                            checked={content.status === true}
                            onChange={handleRadioChange('status')}
                          /> Enable
                          <input type="radio" name="contentStatus" className="ml-5"
                            value={false}
                            checked={content.status === false}
                            onChange={handleRadioChange('status')}
                          /> Disable
                        </div>
                      </FormGroup>
                      <p>
                        Sort ordering still applies to disabled items,
                        though they'll be hidden from users.
                        Content status will affect sub-group items.
                      </p>
                    </div>
                    <div className="col-md-4">
                      <FormGroup controlId="content.name" >
                        <div className="content-input-group">
                          <span className="content-text-heads">
                            Content Name:
                          </span>
                          <FormControl
                            autoFocus
                            type="text"
                            placeholder="Content Name"
                            value={content.videoname}
                            onChange={handleChange('videoname')}
                            className="content-input-field"
                          />
                        </div>
                      </FormGroup>
                      <FormGroup controlId="content.description" >
                        <div className="content-input-group">
                          <span className="content-text-heads">
                            Description:
                          </span>
                          <Form.Control as="textarea" rows={15}
                            autoFocus
                            placeholder="Description"
                            value={content.description} onChange={handleChange('description')}
                            className="group-input-field" />
                        </div>
                      </FormGroup>
                      <FormGroup controlId="content.metadata" >
                        <div className="content-input-group">
                          <span className="content-text-heads">
                            Meta Data:
                          </span>
                          <FormControl
                            autoFocus
                            type="text"
                            placeholder="metadata"
                            value={content.metadata} onChange={handleChange('metadata')}
                            className="content-input-field"
                          />
                        </div>
                      </FormGroup>

                      <FormGroup controlId="content.videoLanguage" >
                        <div className="content-input-group">
                          <span className="content-text-heads">
                            Select Language:
                          </span>
                          <Input
                            type="select"
                            id="data-language"
                            value={content.languageId}
                            className="content-input-field content-dropdown"
                            onChange={handleChange('languageId')}
                          >
                            {
                              languages && languages.map((data, index) => (
                                <option value={data.id} key={index}>{data.name}</option>
                              ))
                            }
                          </Input>
                        </div>
                      </FormGroup>
                      <FormGroup controlId="content.videoPath" >
                        <div className="content-input-group">
                          <span className="content-text-heads">
                            {content.content_type === 'video' ? 'Video Upload:' : 'Document Upload:'}
                          </span>
                          {
                            content.content_type === 'video' &&
                            <FormControl
                              autoFocus
                              type="file"
                              placeholder="video Upload"
                              onChange={uploadVideo}
                              accept="video/*"
                              className="content-input-field"
                            />
                          }
                          {
                            content.content_type === 'document' &&
                            <FormControl
                              autoFocus
                              type="file"
                              placeholder="document Upload"
                              onChange={uploadVideo}
                              accept="application/pdf"
                              className="content-input-field"
                            />
                          }
                        </div>
                      </FormGroup>
                      <div className="video-container">
                        {
                          videoList && videoList.length > 0 &&
                          videoList.filter(x => !x.isdelete).map((data, index) => (
                            <React.Fragment key={index}>
                              <span key={index}>
                                {content.content_type === 'video' &&
                                  <video src={props.documentPath + data.videokey} className="ml-2 content-image-container image-container" onLoadedMetadata={e => {

                                    data.videolength = Math.round(e.target.duration);
                                    //alert(e.target.duration);
                                  }} />}
                                {content.content_type === 'document' && <FontAwesomeIcon icon="file-pdf" size="2x" color="red" className="ml-2 mr-5" />}
                                <label className="mb-2 mr-2 ml-2 pb-2">{data.languageName}<br />{data.videolength > 0 && <span>{data.videolength} sec</span>} </label>
                                <span className="remove-content pull-right" onClick={() => removeVideoContent(data.id)}>
                                  {/* onClick={()=>removeVideoContent(data.id)}> */}
                                  <FontAwesomeIcon icon="times-circle" size="1x" color="grey" className="ml-2" />
                                </span>
                              </span><br />
                            </React.Fragment>
                          ))
                        }
                      </div>
                    </div>
                    <div className="col-md-4">
                      <FormGroup controlId="content.isfeatured" >
                        <div className="content-input-group">
                          <span className="content-text-heads">
                            Is featured content:
                          </span>
                          <br></br>
                          <input type="radio" name="isfeatured" className="ml-5"
                            value={true}
                            checked={content.isfeatured === true}
                            onChange={handleRadioChange('isfeatured')}
                          /> Yes
                          <input type="radio" name="isfeatured" className="ml-5"
                            value={false}
                            checked={content.isfeatured === false}
                            onChange={handleRadioChange('isfeatured')}
                          /> No
                        </div>
                      </FormGroup>

                      {content.content_type === 'video' && <React.Fragment>
                        <FormGroup controlId="content.supplimentary" >
                          <div className="content-input-group">
                            <span className="content-text-heads">
                              Attach supplementary document:
                            </span>
                            <br></br>
                            <input type="radio" name="contentSupplimentary" className="ml-5"
                              value={true}
                              checked={content.supplimentary === true}
                              onChange={handleRadioChange('supplimentary')}
                            /> Yes
                            <input type="radio" name="contentSupplimentary" className="ml-5"
                              value={false}
                              checked={content.supplimentary === false}
                              onChange={handleRadioChange('supplimentary')}
                            /> No
                          </div>
                        </FormGroup>
                        <p>Supplementary documents will be created as sub-group items</p>
                        <FormGroup controlId="content.documentName" >
                          <div className="content-input-group">
                            <span className="content-text-heads">
                              Document Name:
                            </span>
                            <FormControl
                              autoFocus
                              type="text"
                              disabled={!content.supplimentary}
                              placeholder="Document Name"
                              value={content.filename} onChange={handleChange('filename')}
                              className="content-input-field"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup controlId="content.documentDescription" >
                          <div className="content-input-group">
                            <span className="content-text-heads">
                              Description(optional):
                            </span>
                            <Form.Control as="textarea" rows={15}
                              autoFocus
                              placeholder="Document description"
                              value={content.attachment_description} onChange={handleChange('attachment_description')}
                              className="group-input-field" />
                          </div>
                        </FormGroup>
                        <FormGroup controlId="content.documentPath" >
                          <div className="content-input-group">
                            <span className="content-text-heads">
                              Document Upload:
                            </span>
                            <span className="content-image-uploader">
                              {content.attachmentkey && content.attachmentkey.length > 0 &&
                                <FontAwesomeIcon icon="file-pdf" size="2x" color="red" className="mr-2" />}
                              <FormControl
                                autoFocus
                                type="file"
                                placeholder="Document Upload"
                                disabled={!content.supplimentary}
                                onChange={uploadDocument}
                                accept="application/pdf"
                                className="content-input-field"
                              />
                            </span>
                          </div>
                        </FormGroup>
                      </React.Fragment>
                      }
                      <FormGroup controlId="content.thumbnailPath" >
                        <div className="content-input-group">
                          <span className="content-text-heads">
                            Thumbnail Upload:
                          </span>
                          <span className="content-image-uploader">
                            {content.thumbnailkey && content.thumbnailkey.length > 0 && <img src={props.documentPath + content.thumbnailkey} className="mr-2 content-image-container " />}
                            <FormControl
                              autoFocus
                              type="file"
                              placeholder="Thumbnail Upload"
                              accept="image/*"
                              onChange={uploadThumbnail}
                              className="content-input-field"
                            />
                          </span>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-12">
                      {validation.validationId > 0 && <span className="alert message-validation">{validation.validationMessage}</span>}
                      <hr className="content-seperator" />
                      <div className="form-content content-btn-submit ">
                        <a href="/contentlist" className="ml-5"><span className="btn btn-secondary content-back-btn">Back</span></a>
                        <Button block className="btn  btn-info content-save-btn"
                          type="submit">
                          {!isLoading ? 'Save' : 'Please wait...'}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Content