import React, { Component,useState,useMemo,useCallback,useEffect } from 'react';
import {  withRouter } from 'react-router-dom';
import Routes from './route';
import { Auth } from 'aws-amplify';
import './App.css';
import './index.css';
import './Assets/Css/custom.css'
import './Assets/Css/menu.css'
//import './Assets/Css/leftMenu.css'
import './Assets/Css/adminIndex.less'
import tabnulogo from "./Assets/Images/tabnuTempLogo.png"
import footerLogo from "./Assets/Images/watermarkLogos.png"
import {  FormControl, NavDropdown  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Assets/fontawesome';
import {Nav} from "react-bootstrap";
import Axios from 'axios'
import Login from './Components/Login/login';
import Dashboard from './Components/Dashboard/dashboard';
import {Sidebar, InputItem, DropdownItem, Icon, Item, Logo, LogoText} from 'react-sidebar-ui'
import {connect} from 'react-redux'
//import Sidebar from 'react-bootstrap-sidebar';
import Config from './config'
//import Navbar from'./Directives/Menu/navbar'
import Header from'./Directives/Layout/Header/header'
import Menu from'./Directives/Layout/Menu/menu'
import Footer from'./Directives/Layout/Footer/footer'
import AWS from 'aws-sdk';

const apiUrlPrefix =Config.apiUrlPrefix;// serviceWorker.applicationConfig().apiUrlPrefix;
const documentPath =Config.documentUrl; //serviceWorker.applicationConfig().documentUrl;


const App=(props)=> {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthenticating, setAuthenticating] = useState(true);
  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [isVisible,setVisible]=useState(false);
  const [jwtToken,setJwtToken]=useState('');

    useEffect(async() => {
    try {
      const session = await Auth.currentSession();
      // console.log('session');
      // console.log(session);
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
      setAuthenticating(false);
    //   AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    //     IdentityPoolId: 'eu-west-1:7d5c64cd-902c-4896-9c38-052111654ea4',
    //     Logins: {
    //         'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_AeLLeTGXu': user.signInUserSession.idToken.jwtToken//result.getIdToken().getJwtToken()
    //     }
       
    // });
    // console.log('aws cognito details');
    // console.log(AWS.config.credentials);
		} catch(error) {
		  if (error !== 'No current user') {
			console.log(error);
      }
      setAuthenticating(false);
    }
},[userId,userName])

  const handleLogout=async ()=>{
    await Auth.signOut();
    setAuthenticated(false);
		props.history.push('/login');
  }

 const setUser =  user => {
  // console.log(user);
    setUserName(user.attributes['name']);
    setUserId((user.attributes['custom:user_id']));
    setJwtToken(user.signInUserSession.idToken.jwtToken);
    setAuthenticated(true);
  }

  const menuProps={
  userName:userName,
  signOut:handleLogout
}
  const childProps = {
    isAuthenticated: isAuthenticated,
    //userHasAuthenticated: userHasAuthenticated,
    setUser: setUser,
    userId:userId,
    apiUrlPrefix:apiUrlPrefix,
    documentPath:documentPath,
    jwtToken:jwtToken,
    apiHeaderRequest:{headers:{'X-COG-ID':jwtToken}}
  };
  return (
    !isAuthenticating && <React.Fragment >
			{ isAuthenticated ? (
					<React.Fragment>
            <Header  props={menuProps}/>
            <Menu/>
            <div className="content-wrapper">
                <Routes childProps={childProps}/>
            </div>
            <Footer/>
				</React.Fragment>
        ) : 
        (
					<Routes childProps={childProps}/>
				)
			}
		</React.Fragment>
  );
}
export default withRouter(App)

