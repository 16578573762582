
import React, { useState,useEffect,useCallback } from "react";
//import { AgGridColumn, AgGridReact } from 'ag-grid-community';
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';
import BtnCellRenderer from "./BtnCellRenderer";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ScaleLoader from "react-spinners/ScaleLoader";

const OrganisationList=(props)=>{

    const [organisationList,setOrganisationList]=useState([]);
    const [isLoading,setLoading]=useState(false);
    
    ///----ag grid----//
    const [gridApi,setGridApi]=useState()
    const columnDefs = [
     
      { headerName: "Name", field: "name",},
      { headerName: "Count", field: "count",},
      { headerName: "Action", cellRenderer: "BtnCellRenderer" },
    ]  
  

    const defaultColDef = {
      sortable: true,
      editable: true,
      flex: 1, filter: true,
      floatingFilter: true, 
    }
  
    const onGridReady = (params) => {
        setGridApi(params.api);
    
        loadOrganisationList();
        params.api.paginationGoToPage(10);
       
      };
    
      const onPageSizeChanged = (newPageSize) => {
        gridApi.paginationSetPageSize(Number(newPageSize));
      };
    //a g grid end----//
     
    // useEffect(() => {
    //     loadOrganisationList();
    // },[]);
    
    const loadOrganisationList=useCallback(()=>{
        setLoading(true);
        Axios.post(props.apiUrlPrefix + "/getOrganisationsCount",null,props.apiHeaderRequest)
        .then((res) => {
            if(res!=null && res.data!=null && res.data.statusCode===200 && res.data.body.length>0 ){
                var lst = res.data.body;
                setOrganisationList(lst);
            }
            setLoading(false);
        })
        .catch(err => {  
            console.log(err) ;
            setLoading(false);
        });
    },[])

    const btnDeleteOrganisation=(id)=>{
        setLoading(true);
        const params ={
            orgId:id
        }
        Axios.post(props.apiUrlPrefix + "/archiveOrg",params,props.apiHeaderRequest)
        .then((res) => {
            if(res!=null && res.data!=null && res.data.statusCode===200 && res.data.body.id>0 ){
                alert('Organisation has been archived successfully.');
                loadOrganisationList();
            }
            setLoading(false);
        })
        .catch(err => {  
            console.log(err) ;
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            <div className={isLoading?'loader-style':''}  >
        <ScaleLoader
          size={150}
          color={"white"}
          loading={isLoading}
        />
      </div>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 ">
                            <h2>Organisation List</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="content-fluid">
<div className="card">

</div>
<div className="card">
<div className="card-body">
<div className="row">
    <div className="col-md-12">
    <span className="pull-left">Page size: <select onChange={(e)=>onPageSizeChanged(e.target.value)}>
        <option value='10'>10</option>
        <option value='25'>25</option>
        <option value='50'>50</option>
        <option value='100'>100</option>
      </select>
    </span>
    <a href="/organisation"><span className="btn btn-sm btn-info float-right "    >+ Create Organisation</span></a>
    </div>
    <div className="col-md-12 ag-theme-alpine " >
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={10}
          domLayout={'autoHeight'}
          frameworkComponents={{BtnCellRenderer}}
          context={{ btnDeleteOrganisation }}
          paginationAutoPageSize={true}
      rowData={organisationList}
      onGridReady={onGridReady}
      gridOptions={{}}
          >
        </AgGridReact>
    </div>
</div>

     
</div>
</div>
                </div>
            </div>
        </React.Fragment>
        
    )
}

export default OrganisationList