import React, { useState, useEffect, Component } from "react";
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';
import './organisationStyle.css'
import { Input } from "reactstrap"
import { Button, FormGroup, FormControl } from "react-bootstrap";
//import ReactS3 from 'react-s3'
import Config from '../../config'
import BtnCellRenderer from "../User/BtnCellRenderer";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import ScaleLoader from "react-spinners/ScaleLoader";
import CheckboxTree from 'react-checkbox-tree';

export default class Organisation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            organisation: { id: 0, name: '', status: false, count: 0, typeId: 0 },
            userlist: [],
            validation: { validationId: 0, validationMessage: '' },
            isLoading: false,
            types: [],
            groupList: [],
            gridApi: {},
            columnDefs: [
                { headerName: "Name", field: "name", },
                { headerName: "Email", field: "email", },
                { headerName: "Role", field: "role", },
                { headerName: "Action", cellRenderer: "BtnCellRenderer" },
            ],
            defaultColDef: {
                sortable: true,
                editable: true,
                flex: 1, filter: true,
                floatingFilter: true,
            },
            checked: [],
            expanded: [],
        }
    }

    componentDidMount = async () => {
        try {
            const id = parseInt(this.props.match.params.id);
            await this.loadTypes();
            if (id > 0) {
                await this.loadOrganisation(id);
            }
            if (parseInt(this.state.organisation.typeId) > 0 && this.state.types.length > 0) {
                await this.loadGroups(parseInt(this.state.organisation.typeId));
            }
        }
        catch (error) {
            if (error !== 'No current user') {
                console.log(error);
            }
        }
    };

    loadOrganisation = async (id) => {
        this.setState({ isLoading: true });
        const params = {
            orgId: id
        }
        await Axios.post(this.props.apiUrlPrefix + "/getOrgUsersForEdit", params, this.props.apiHeaderRequest)
            .then((res) => {
                if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
                    let obj = res.data.body[0];
                    const org = {
                        id: obj.id,
                        name: obj.name,
                        status: obj.status,
                        // count: obj.usr.length,
                        typeId: obj.type_id
                    }
                    var groups = obj.orgGroups.map(function (val) {
                        return val.groupId.toString();
                    });
                    //   setOrganisation(org);
                    //   console.log(organisation);
                    //   setUserlist(obj.usr);
                    //   console.log(userlist);
                    this.setState({ organisation: org });
                    this.setState({ userlist: obj.usr });
                    this.setState({ checked: groups });
                    //  await loadTypes();
                    //  await loadGroups(parseInt(obj.type_id));
                }
                this.setState({ isLoading: false });
                // setLoading(false);
                // console.log(isLoading);
            })
            .catch(err => {
                console.log(err);
                //setLoading(false);
                this.setState({ isLoading: false });
            });
    };

    loadTypes = async () => {
        this.setState({ isLoading: true });
        await Axios.post(this.props.apiUrlPrefix + "/getTypesAdmin", null, this.props.apiHeaderRequest)
            .then((res) => {
                if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
                    let typeList = res.data.body;
                    this.setState({ types: typeList });
                    //setTypes(typeList);
                    // var groups=[];
                    // typeList.map((data, index) => {
                    //   groups.push({ label: data.name, value: data.id ,children:[] })
                    // })
                    // setGroupList(groups);
                }
                // setLoading(false);
                this.setState({ isLoading: false });
            })
            .catch(err => {
                console.log(err);
                //setLoading(false);
                this.setState({ isLoading: false });
            });
    }

    loadGroups = async (objTypeId) => {
        const params = {
            userId: this.props.userId,
        };
        //setLoading(true);
        this.setState({ isLoading: true });
        Axios.post(this.props.apiUrlPrefix + "/getGroupsAdmin?", params, this.props.apiHeaderRequest)
            .then((res) => {
                if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
                    if (parseInt(objTypeId) > 0) {
                        if (parseInt(objTypeId) === 3) {
                            var tempGroupList = [];
                            var typeList = [];
                            this.state.types.filter(x => x.id !== parseInt(objTypeId)).map((type) => {
                                let lstGroups = res.data.body.filter(x => x.type_id === parseInt(type.id));
                                var groups = [];
                                lstGroups.map((data) => {
                                    groups.push({  value: data.id, label: data.name, })
                                })
                                tempGroupList.push({  value: type.id.toString()+"|"+type.name, label: type.name, children: groups, });
                                typeList.push(type.id.toString()+"|"+type.name);
                            })
                            this.setState({ groupList: tempGroupList });
                            this.setState({ expanded: typeList });
                        }
                        else {
                            let lstGroups = res.data.body.filter(x => x.type_id === parseInt(objTypeId));
                            var type = this.state.types.filter(x => x.id === parseInt(objTypeId));
                            var groups = [];
                            lstGroups.map((data) => {
                                groups.push({ value: data.id, label: data.name })
                            })
                            var groupList = [{ value: type[0].id, label: type[0].name, expanded: true, children: groups }]
                            this.setState({ expanded: [type[0].id] });
                            this.setState({ groupList: groupList });
                        }
                    }
                    else {
                        //setGroupList([]);
                        this.setState({ groupList: [] });
                    }
                }
                //setLoading(false);
                this.setState({ isLoading: false });
            })
            .catch(err => {
                console.log(err);
                // setLoading(false);
                this.setState({ isLoading: false });
            });
    };

    handleChange = event => {
        // this.setState({
        //     [event.target.id]: event.target.value
        // });
        this.setState(prevState => ({
            organisation: {                   // object that we want to update
                ...prevState.organisation,    // keep all other key-value pairs
                [event.target.id]: event.target.value      // update the value of specific key
            }
        }))
        if ([event.target.id] == 'typeId') {
            this.loadGroups(parseInt(event.target.value));
        }
    };

    // handleRadioChange = input => ({ target: { value } }) => {
    //     setOrganisation({
    //         ...organisation,
    //         [input]: value == "true" ? true : false
    //     })
    // }

    validateForm = () => {
        this.setState({ validation: { validationId: 0, validationMessage: '' } });
        if (this.state.organisation.name.length <= 0) {
            this.setState({ validation: { validationId: 1, validationMessage: 'Enter the organisation name' } });
            return false;
        }
        return true;
    }

    handleSubmit = async event => {
        event.preventDefault();
        if (this.state.isLoading) { return; }

        const validationStatus = this.validateForm();
        if (!validationStatus) {
            return false;
        }

        try {
            this.setState({ isLoading: true });
            var checkedValues = this.state.checked.map(function (val) {
                return parseInt(val);
            });
            if (this.state.organisation.id > 0) {
                const params = {
                    orgId: this.state.organisation.id,
                    status: this.state.organisation.status,
                    typeId: this.state.organisation.typeId,
                    groupIds: checkedValues
                };
                const res = await Axios.post(this.props.apiUrlPrefix + "/updateOrgStatus", params, this.props.apiHeaderRequest);
                this.setState({ isLoading: false });
                if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.id > 0) {
                    alert("Information saved");
                    this.props.history.push("/organisationlist");
                }
                else {
                    this.setState({ validation: { validationId: 2, validationMessage: res.data } });
                }
            }
            else {

                const params = {
                    name: this.state.organisation.name,
                    typeId: this.state.organisation.typeId,
                    groupIds: checkedValues
                    //status:organisation.status,
                };
                const res = await Axios.post(this.props.apiUrlPrefix + "/createOrganisation", params, this.props.apiHeaderRequest);
                this.setState({ isLoading: false });
                if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.id > 0) {
                    alert("Information saved");
                    this.props.history.push("/organisationlist");
                }
                else {
                    this.setState({ validation: { validationId: 2, validationMessage: res.data } });
                }
                this.setState({ isLoading: false });
            }
        } catch (error) {
            console.log(error.message);

            this.setState({ isLoading: false });
        }
    }
    ///----ag grid----//

    onPaginationChange = (pageSize) => {
        this.state.gridApi.api.paginationSetPageSize(Number(pageSize))
    }
    //a g grid end----//

    render() {
        return (
            <React.Fragment>
                <div className={this.state.isLoading ? 'loader-style' : ''}  >
                    <ScaleLoader
                        size={150}
                        color={"white"}
                        loading={this.state.isLoading}
                    />
                </div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 ">
                                <h2>Organisation <small>create/update</small></h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="content-fluid">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={this.handleSubmit} className="organisation-form ">
                                            <FormGroup controlId="id" >
                                                <div className="">
                                                    <FormControl
                                                        autoFocus
                                                        type="hidden"
                                                        value={this.state.organisation.id} onChange={this.handleChange}
                                                    />
                                                </div>
                                            </FormGroup>

                                            <FormGroup controlId="name" >
                                                <div className="organisation-input-group">
                                                    <span className="organisation-text-heads">
                                                        Organisation Name:
                                                    </span>
                                                    <FormControl
                                                        autoFocus
                                                        type="textarea"
                                                        rows="5"
                                                        placeholder=" Organisation Name"
                                                        value={this.state.organisation.name} onChange={this.handleChange}
                                                        className="organisation-input-field"
                                                    />
                                                </div>
                                            </FormGroup>

                                            {
                                                this.state.organisation.id > 0 && <FormGroup controlId="user.status" >
                                                    <div className="organisation-input-group">
                                                        <span className="organisation-text-heads">
                                                            Status:
                                                        </span>
                                                        <br />
                                                        <input type="radio" name="organisationStatus" className="ml-5"
                                                            value={true}
                                                            checked={this.state.organisation.status === true}
                                                            onChange={this.handleChange}
                                                        /> Enable
                                                        <input type="radio" name="organisationStatus" className="ml-5"
                                                            value={false}
                                                            checked={this.state.organisation.status === false}
                                                            onChange={this.handleChange}
                                                        /> Disable
                                                    </div>
                                                    {this.state.validation.validateId === 3 && <span className="registration-validation">{this.state.validation.validateMessage}</span>}
                                                </FormGroup>
                                            }

                                            <FormGroup controlId="typeId" >
                                                <div className="content-input-group">
                                                    <span className="content-text-heads">
                                                        Select Type:
                                                    </span>
                                                    <Input
                                                        type="select"
                                                        id="typeId"
                                                        value={this.state.organisation.typeId}
                                                        className="content-input-field content-dropdown"
                                                        onChange={this.handleChange}
                                                    >
                                                        <option value="0">Select Type</option>
                                                        {
                                                            this.state.types && this.state.types.map((data, index) => (
                                                                <option value={data.id} key={index}>{data.name}</option>
                                                            ))
                                                        }
                                                    </Input>
                                                </div>
                                            </FormGroup>

                                            <FormGroup controlId="groupIds" >
                                                <div className="content-input-group">
                                                    <span className="content-text-heads">
                                                        Select Group:
                                                    </span>
                                                    <CheckboxTree
                                                        nodes={this.state.groupList}
                                                        checked={this.state.checked}
                                                        expanded={this.state.expanded}
                                                        onCheck={checked => this.setState({ checked })}
                                                        onExpand={expanded => this.setState({ expanded })}

                                                    />
                                                </div>
                                            </FormGroup>

                                            {this.state.validation.validationId > 0 && <span className="alert message-validation">{this.state.validation.validationMessage}</span>}
                                            <hr className="organisation-seperator" />
                                            <div className="form-group organisation-btn-submit ">

                                                <a href="/organisationlist" className="ml-5"><span className="btn btn-secondary organisation-back-btn">Back</span></a>
                                                <Button block className="btn  btn-info organisation-save-btn"
                                                    type="submit">
                                                    {!this.state.isLoading ? 'Save' : 'Please wait...'}
                                                </Button>
                                            </div>
                                            <div style={{ marginTop: '15px' }}>
                                                {/* {validation.validateId==4 && <span className="registration-validation">{validation.validateMessage}</span>} */}
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span className="pull-left">Page size: <select onChange={(e) => this.onPaginationChange(e.target.value)}>
                                                    <option value='10'>10</option>
                                                    <option value='25'>25</option>
                                                    <option value='50'>50</option>
                                                    <option value='100'>100</option>
                                                </select>
                                                </span>
                                                {/* <a href="/group"><span className="btn btn-sm btn-info float-right "    >+ Create Group</span></a> */}
                                            </div>
                                            <div className="col-md-12 ag-theme-alpine " style={{ height: '500px' }}>
                                                <AgGridReact
                                                    columnDefs={this.state.columnDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    onGridReady={this.state.userlist}
                                                    pagination={true}
                                                    paginationPageSize={10}
                                                    frameworkComponents={{ BtnCellRenderer }}
                                                    paginationAutoPageSize={true}
                                                    rowData={this.state.userlist}
                                                    //paginationAutoPageSize={true}
                                                    gridOptions={{}}
                                                    colResizeDefault={"shift"}
                                                    animateRows={true}
                                                    pivotPanelShow="always"
                                                    rowSelection="multiple"
                                                >
                                                </AgGridReact>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">


                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}