import React from'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../../Assets/fontawesome';

const Menu=()=>{
    return (
<React.Fragment>
{/* Main Sidebar Container */}
<aside className="main-sidebar sidebar-light-primary elevation-4">
  {/* Brand Logo */}
  {/* <a href="index3.html" className="brand-link">
    <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />
    <span className="brand-text font-weight-light">AdminLTE 3</span>
  </a> */}
  {/* Sidebar */}
  <div className="sidebar">
    {/* Sidebar user panel (optional) */}
    {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
      <div className="image">
        <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
      </div>
      <div className="info">
        <a href="#" className="d-block">Alexander Pierce</a>
      </div>
    </div> */}
    {/* SidebarSearch Form */}
    {/* <div className="form-inline">
      <div className="input-group" data-widget="sidebar-search">
        <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
        <div className="input-group-append">
          <button className="btn btn-sidebar">
            <i className="fas fa-search fa-fw" />
          </button>
        </div>
      </div>
    </div> */}
    {/* Sidebar Menu */}
    <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
      
        <li className="nav-item">
          <a href="/dashboard" className="nav-link">
          <FontAwesomeIcon icon="tachometer-alt" size="1x" color="black" className="mr-3"/>
            <p>
                Dashboard
            </p>
          </a>
        </li>
        <li className="nav-item">
          <a href="/grouplist" className="nav-link">
          <FontAwesomeIcon icon="layer-group" size="1x" color="black" className="mr-3"/>
            <p>
               Group Management
            </p>
          </a>
        </li>
        <li className="nav-item">
          <a href="/contentlist" className="nav-link">
          <FontAwesomeIcon icon="box" size="1x" color="black" className="mr-3"/>
            <p>
               Content Management
            </p>
          </a>
        </li>
        <li className="nav-item">
          <a href="/userlist" className="nav-link">
          <FontAwesomeIcon icon="user" size="1x" color="black" className="mr-3"/>
            <p>
               User Management
            </p>
          </a>
        </li>
        <li className="nav-item">
          <a href="/organisationlist" className="nav-link">
          <FontAwesomeIcon icon="user" size="1x" color="black" className="mr-3"/>
            <p>
               Organisation Management
            </p>
          </a>
        </li>
       </ul>
    </nav>
    {/* /.sidebar-menu */}
  </div>
  {/* /.sidebar */}
</aside>

</React.Fragment>
    )

}
export default Menu