export default {
	apiGateway: {
		REGION: process.env.REACT_APP_COGNITOREGION,//'eu-west-1',
		URL: 'YOUR_API_GATEWAY_URL'
	},
	cognito: {
		REGION:process.env.REACT_APP_COGNITOREGION,//'eu-west-1',
		USER_POOL_ID:process.env.REACT_APP_COGNITOUSERPOOL,//'eu-west-1_AeLLeTGXu',
		APP_CLIENT_ID: process.env.REACT_APP_COGNITOCLIENTID,//'7rc0qojs9bc928oe5gjt0bqtbo',
		IDENTITY_POOL_ID: 'YOUR_IDENTITY_POOL_ID'
	},
	apiUrlPrefix:process.env.REACT_APP_APIURLPREFIX,//'https://4adasgxei2.execute-api.eu-west-1.amazonaws.com/dev',
	documentUrl:process.env.REACT_APP_DOCUMENTURL,//'https://dev-s3-tabnu-training-portal.s3.amazonaws.com/',
	accessKey:process.env.REACT_APP_ACCESSKEY,//'AKIAYCBUF3KCXXN4KEUO',//'AKIAYCBUF3KCVJ25H2OE',
	secretAccessKey:process.env.REACT_APP_SECRETKEY,//'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',//'FuRES3gcpB8Bq1QgfXNIHr355KPS0JORUcPhMl53',
	bucketName:process.env.REACT_APP_DOCUMENTBUCKETNAME,//'dev-s3-tabnu-training-portal'
};
