import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';

class BtnCellRenderer extends Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler() {
    this.props.clicked(this.props.value);
  }
  btnDeleteUser=(event)=>{
    let deletedRow = this.props.node.data;
    this.props.context.btnDeleteUser(deletedRow.id);
  }
  render() {
    return (
    <React.Fragment>
        <a href={`/user/${this.props.data.id}`} className="mr-2"><span> <FontAwesomeIcon icon="edit"  /> Edit</span></a>
        <a href="#" className="action-icon-space" onClick={this.btnDeleteUser}><span> <FontAwesomeIcon icon="archive"  /> Archive</span></a>
    </React.Fragment>
    )
  }
}

export default BtnCellRenderer;