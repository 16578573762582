
import React, { useState,useEffect,useCallback } from "react";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import "./dashboardStyle.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { Auth } from 'aws-amplify';
import Axios from 'axios'
import { AgGridColumn, AgGridReact } from 'ag-grid-community';
import ReactPaginate from 'react-paginate';
import UserContainer from './userContainer'
import Pagination from './pagination'
import ScaleLoader from "react-spinners/ScaleLoader";

const Dashboard=(props)=>{
    const [pendingUsers,setPendingUsers]=useState([]);
    const [offset,setOffset]=useState(0);
    const [perPage,setPerPage]=useState(10);
    const [filterRecords,setFilerRecords]=useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setLoading] = useState(false);
    const [postsPerPage] = useState(10);
    const [searchText,setSearchText]=useState('');
    const [userId,setUserId]=useState(0);
    
    useEffect(() => {
        const loadPendingUsers=async ()=>{
            setLoading(true);
            await  Axios.post(props.apiUrlPrefix + "/getUsersForApproval",null,props.apiHeaderRequest)
            .then((res) => {
                if(res!=null && res.data!=null && res.data.statusCode===200 && res.data.body.length>0 ){
                    var lst = [];
                    res.data.body.map(data=>{
                        data.filterItem = true;
                        lst.push(data);
                    })
                    setPendingUsers(lst);
                }
                else{
                    setPendingUsers([]);
                }
                setLoading(false);
            })
            .catch(err => {  
                console.log(err) ;
                setLoading(false);
            });
        }

          loadPendingUsers();
    },[userId]);

    
  // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const records = pendingUsers.filter(x=>x.filterItem===true).slice(indexOfFirstPost, indexOfLastPost);

  // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const clickIgnore=(id)=>{
        setLoading(true);
        const params ={
            userId:id
        }
        Axios.post(props.apiUrlPrefix + "/archiveUser",params,props.apiHeaderRequest)
        .then((res) => {
            if(res!=null && res.data!=null && res.data.statusCode===200 && res.data.body.id>0 ){
              setUserId(id);
            }
            setLoading(false);
        })
        .catch(err => {  
            console.log(err) ;
            setLoading(false);
        });
        }
      
    const clickApprove=(id)=>{
        setLoading(true);
          const params={
            userId:id
          };
          Axios.post(props.apiUrlPrefix + "/updateUserStatus",params,props.apiHeaderRequest)
          .then((res) => {
              if(res!=null && res.data!=null && res.data.statusCode===200 && res.data.body.id>0 ){
                setUserId(id);
              }
              setLoading(false);
          })
          .catch(err => {  
              console.log(err) ;
              setLoading(false);
          });
        }

    const handleSearchChange = value => {
            setSearchText(value);
            //filterData();
        }
    const filterData=()=>{
      
        let lst = [];
        if(searchText==null || searchText.length==0 || searchText===''){
            pendingUsers.map(data=>{
                data.filterItem = true;
                lst.push(data);
            } )
        }
        else{
            pendingUsers.map(data=>{
                if(data.email.toLowerCase().includes(searchText.toLowerCase()) || data.name.toLowerCase().includes(searchText.toLowerCase()) 
                //|| data.company_name.toLowerCase().includes(searchText.toLowerCase())
                 || data.designation.toLowerCase().includes(searchText.toLowerCase())){
                    data.filterItem = true;
                }
                else{
                    data.filterItem = false;
                }
                lst.push(data);
            } )
        }
        setPendingUsers(lst);
    }
    return (
        <React.Fragment>
            <div className={isLoading?'loader-style':''} >
        <ScaleLoader
          size={150}
          color={"white"}
          loading={isLoading}
        />
      </div>
            <div className="card">
                <div className="card-header">
                <h2>Dashboard</h2>
                </div>
                <div className="card-body">
                <div className="content">
                <div className="content-fluid">
                <div className="row">
              
                <div className="col-md-12 ">
            <span className="pull-left"><b>Pending Users {pendingUsers.length}</b>
            
            </span>
            <span className="mr-2">
            <FormControl
            autoFocus
            type="text"
            value={searchText}
            placeholder="Search"
            onKeyUp={filterData} 
            onChange={e=>handleSearchChange(e.target.value)}
            className="registration-input-field"
          />
            </span>
            <span className="pull-right">
                <Pagination
                perPage={10}
                total={pendingUsers.length}
                paginate={paginate}
                currentPage={currentPage}
                />
            </span>
                </div>
                <div className="col-md-12">
                    <UserContainer users={records} loading={isLoading} clickIgnore={clickIgnore} clickApprove={clickApprove} />
                </div>
                
            </div>
                </div>
            </div>
                </div>
            </div>
           
           
           
        </React.Fragment>
    )
}
export default Dashboard