import React, { useState, useEffect, useCallback } from "react";
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';
import './groupStyle.css'
import { Input } from "reactstrap"
import { Button, FormGroup, FormControl, Form } from "react-bootstrap";
import ReactS3 from 'react-s3'
import Config from '../../config'
import ScaleLoader from "react-spinners/ScaleLoader";

const imageConfig = {
  bucketName: Config.bucketName,//'tabnu-client-ui' ,//
  dirName: 'thumbnail', /* optional *///'Images',//
  region: 'eu-west-1',
  accessKeyId: Config.accessKey,//'AKIAJYS6QUY77JM5TVVQ',//
  secretAccessKey: Config.secretAccessKey,//'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',//
}

const Group = (props) => {
  const [group, setGroup] = useState({
    id: 0, name: '', subGroup: '', categoryId: 0,
    sequence: 0, status: true, imagePath: '', description: '',typeId:0
  });
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [validation, setValidation] = useState({ validationId: 0, validationMessage: '' });
  const [isLoading, setLoading] = useState(false);

  const loadCategories = async () => {
    setLoading(true);
    await Axios.post(props.apiUrlPrefix + "/getCategoriesAdmin", null, props.apiHeaderRequest)
      .then((res) => {
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {

          let categoryList = res.data.body;
          setCategories(categoryList);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadTypes = async () => {
    setLoading(true);
    await Axios.post(props.apiUrlPrefix + "/getTypesAdmin", null, props.apiHeaderRequest)
      .then((res) => {
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
          let typeList = res.data.body;
          setTypes(typeList);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadGroup = useCallback((id) => {
    setLoading(true);
    Axios.post(props.apiUrlPrefix + "/getGroupsAdmin", null, props.apiHeaderRequest)
      .then((res) => {
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
          var lst = res.data.body;

          var filterGroup = lst.find(x => x.id == id);
          const selectedGroup = {
            id: filterGroup.id,
            name: filterGroup.name,
            subGroup: filterGroup.subgroup,
            categoryId: filterGroup.category,
            sequence: filterGroup.sequence,
            status: filterGroup.status,
            imagePath: filterGroup.default_image_path,
            description: filterGroup.description,
            typeId:filterGroup.type_id
          }
          setGroup(selectedGroup);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, [])

  const handleChange = input => ({ target: { value } }) => {
    setGroup({
      ...group,
      [input]: value
    })
  }

  const handleRadioChange = input => ({ target: { value } }) => {
    setGroup({
      ...group,
      [input]: value == "true" ? true : false
    })
  }

  const uploadImage = event => {
    //event.preventDefault();
    var file = event.target.files[0];
    var imagePath = '';
    console.log(file);
    setLoading(true);
    ReactS3.uploadFile(file, imageConfig)
      .then(data => {
        console.log(data);
        console.log(group);
        imagePath = data.key;
        let obj = group;
        obj.imagePath = data.key;
        setGroup(obj);
        setLoading(false);
      }
      )
      .catch(err => {
        console.error(err);
        setLoading(false);
      }
      )
  }

  const validateForm = () => {
    setValidation({ validationId: 0, validationMessage: '' });
    if (group.name===null || group.name.length <= 0) {
      setValidation({ validationId: 1, validationMessage: 'Enter the group name' });
      return false;
    }
    else if (group.description===null || group.description.length <= 0) {
      setValidation({ validationId: 4, validationMessage: 'Enter the description' });
      return false;
    }
    else if (group.categoryId <= 0) {
      setValidation({ validationId: 2, validationMessage: 'Please select category' });
      return false;
    }
    else if (group.imagePath===null || group.imagePath.length <= 0) {
      setValidation({ validationId: 3, validationMessage: 'Please select thumbnail image' });
      return false;
    }
    else if (group.typeId <= 0) {
      setValidation({ validationId: 5, validationMessage: 'Please select type' });
      return false;
    }
    return true;
  }

  const handleSubmit = async event => {
    event.preventDefault();
    if (isLoading) { return; }

    const validationStatus = validateForm();
    if (!validationStatus) {
      return false;
    }
    try {
      setLoading(true);

      if (group.id > 0) {
        const params = {
          groupId: group.id,
          name: group.name,
          status: group.status,
          subGroup: group.subGroup,
          description: group.description,
          defaultImagePath: group.imagePath,
          categoryId: parseInt(group.categoryId),
          sequence: parseInt(group.sequence),
          typeId:parseInt(group.typeId)
        };
        const res = await Axios.post(props.apiUrlPrefix + "/updateGroup", params, props.apiHeaderRequest);
        setLoading(false);
        if (res != null && res.data.statusCode === 200 && res.data.body.id > 0) {
          alert("Information saved");
          props.history.push("/grouplist");
        }
      }
      else {
        const params = {
          name: group.name,
          subGroup: group.subGroup,
          categoryId: parseInt(group.categoryId),
          sequence: parseInt(group.sequence),
          status: group.status,
          defaultImagePath: group.imagePath,
          description: group.description,
          typeId:parseInt(group.typeId)
        };
        const res = await Axios.post(props.apiUrlPrefix + "/createGroup", params, props.apiHeaderRequest);
        if (res != null && res.data.statusCode === 200 && res.data.body.id > 0) {
          alert("Information saved");
          props.history.push("/grouplist");
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  }

  useEffect(async () => {
    await loadCategories();
    await loadTypes();
    const id = parseInt(props.match.params.id);
    if (id > 0) {
      await loadGroup(id);
    }
  }, []);

  return (
    <React.Fragment>
      <div className={isLoading ? 'loader-style' : ''}  >
        <ScaleLoader
          size={150}
          color={"white"}
          loading={isLoading}
        />
      </div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 ">
              <h2>Group <small>create/update</small></h2>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="content-fluid">

          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <form onSubmit={handleSubmit} className="group-form row">
                    <FormGroup controlId="group.id" >
                      <div className="">
                        <FormControl
                          autoFocus
                          type="hidden"
                          placeholder="Email"
                          value={group.id} onChange={handleChange('id')}
                        />
                      </div>
                    </FormGroup>

                    <div className="col-md-4">
                      <FormGroup controlId="group.name" >
                        <div className="group-input-group">
                          <span className="group-text-heads">
                            Group Name:
                          </span>
                          <FormControl
                            autoFocus
                            type="text"
                            placeholder="Group Name"
                            value={group.name} onChange={handleChange('name')}
                            className="group-input-field"
                          />
                        </div>
                        {/* {validation.validateId===1 && <span className="registration-validation">{validation.validateMessage}</span>} */}
                      </FormGroup>

                      <FormGroup controlId="group.subGroup" >
                        <div className="group-input-group">
                          <span className="group-text-heads">
                            Sub Group Name:
                          </span>
                          <FormControl
                            autoFocus
                            type="text"
                            placeholder="Sub  Group Name"
                            value={group.subGroup} onChange={handleChange('subGroup')}
                            className="group-input-field"
                          />
                        </div>
                        {/* {validation.validateId===1 && <span className="registration-validation">{validation.validateMessage}</span>} */}
                      </FormGroup>

                      <FormGroup controlId="group.sequence" >
                        <div className="group-input-group">
                          <span className="group-text-heads">
                            Sequence:
                          </span>
                          <FormControl
                            autoFocus
                            type="text"
                            placeholder="Sequence"
                            value={group.sequence} onChange={handleChange('sequence')}
                            className="group-input-field"
                          />
                        </div>
                        {/* {validation.validateId===1 && <span className="registration-validation">{validation.validateMessage}</span>} */}
                      </FormGroup>

                      <FormGroup controlId="group.status" >
                        <div className="group-input-group">
                          <span className="group-text-heads">
                            Status:
                          </span>
                          <br />
                          <input type="radio" name="groupStatus" className="ml-5"
                            value={true}
                            checked={group.status === true}
                            onChange={handleRadioChange('status')}
                          /> Enable
                          <input type="radio" name="groupStatus" className="ml-5"
                            value={false}
                            checked={group.status === false}
                            onChange={handleRadioChange('status')}
                          /> Disable
                        </div>
                      </FormGroup>

                    </div>
                    <div className="col-md-4">

                      <FormGroup controlId="group.typeId" >
                        <div className="group-input-group">
                          <span className="group-text-heads">
                            Select Type:
                          </span>
                          <Input
                            type="select"
                            id="data-Institute"
                            value={group.typeId}
                            className="group-input-field group-dropdown"
                            onChange={handleChange('typeId')}
                          >
                            <option value="0">Select Type</option>
                            {
                              types && types.map((data, index) => (
                                <option value={data.id} key={index}>{data.name}</option>
                              ))
                            }

                          </Input>
                        </div>
                      </FormGroup>

                      <FormGroup controlId="group.categoryId" >
                        <div className="group-input-group">
                          <span className="group-text-heads">
                            Select Category:
                          </span>
                          <Input
                            type="select"
                            id="data-Institute"
                            value={group.categoryId}
                            className="group-input-field group-dropdown"
                            onChange={handleChange('categoryId')}
                          >
                            <option value="0">Select Category</option>
                            {
                              categories && categories.map((data, index) => (
                                <option value={data.id} key={index}>{data.name}</option>
                              ))
                            }

                          </Input>
                        </div>
                      </FormGroup>

                      <FormGroup controlId="group.imagePath" >
                        <div className="group-input-group">
                          <span className="group-text-heads">
                            Image Path:
                          </span>
                          <span className="group-image-uploader">
                            {group && group.imagePath && group.imagePath.length > 0 && <img src={props.documentPath + group.imagePath} className="group-image-container mr-2" />}
                            <FormControl
                              autoFocus
                              type="file"
                              placeholder="sequence"
                              accept="image/*"
                              onChange={uploadImage}
                              className="group-uploader-field "
                            />

                          </span>
                        </div>
                      </FormGroup>

                    </div>
                    <div className="col-md-4">
                      <FormGroup controlId="group.description" >
                        <div className="group-input-group">
                          <span className="group-text-heads">
                            Description:
                          </span>
                          <Form.Control as="textarea" rows={15}
                            autoFocus
                            placeholder="Description"
                            value={group.description} onChange={handleChange('description')}
                            className="group-input-field" />
                        </div>
                      </FormGroup>

                    </div>
                    <div className="col-md-12">
                      {validation.validationId > 0 && <span className="alert message-validation">{validation.validationMessage}</span>}
                      <hr className="group-seperator" />
                      <div className="form-group group-btn-submit ">

                        <a href="/grouplist" className="ml-5"><span className="btn btn-secondary group-back-btn">Back</span></a>
                        <Button block className="btn  btn-info group-save-btn"
                          type="submit">
                          {!isLoading ? 'Save' : 'Saving'}
                        </Button>
                      </div>
                      <div style={{ marginTop: '15px' }}>
                      </div>
                    </div>
                  </form>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default Group