
//import logo2 from '../../Assets/Images/logo-2.png'
import tabnulogo from "../../Assets/Images/tabnuTempLogo.png"
import zsahlogo from "../../Assets/Images/zsahLogo.png"
import tableaulogo from "../../Assets/Images/tableauLogo.png"

import React, { useState, useCallback, useEffect } from "react";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import "./loginStyle.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { Auth } from 'aws-amplify';
import Axios from 'axios'
//import {connect} from 'react-redux'
//import LoginService from './loginService'


const Login = (props) => {
  const [userDetail, setUserDetail] = useState({ email: '', password: '' });
  const [isLoading, setLoading] = useState(false);
  const [validation, setValidation] = useState({});
  const [status, setStatus] = useState(false);

  useEffect(() => {
    submitLogin()
  }, [isLoading])

  const validateForm = () => {
    const currentValidation = {};
    if (validation.validateId > 0) {
      currentValidation.validateId = 0;
      currentValidation.validateMessage = '';
      setValidation(currentValidation);
    }

    else if (userDetail == null && userDetail.email.length === 0) {
      currentValidation.validateId = 1;
      currentValidation.validateMessage = 'email is required';
      setValidation(currentValidation);
      return false;
    }
    else if (userDetail == null && !ValidateEmail(userDetail.email)) {
      currentValidation.validateId = 2;
      currentValidation.validateMessage = 'email pattern is not macthing';
      setValidation(currentValidation);
      return false;
    }
    else if (userDetail == null && userDetail.password.length === 0) {
      currentValidation.validateId = 3;
      currentValidation.validateMessage = 'password is required';
      setValidation(currentValidation);
      return false;
    }
    return true;
    //return this.state.email.length > 0 && this.state.password.length > 0;
  }

  const ValidateEmail = (mail) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return true;
    }
    return false;
  }

  async function submitLogin() {
    try {
      const currentValidation = {};

      if (isLoading) {
        const params = {
          emailId: userDetail.email.toLowerCase(),
        };
        const res = await Axios.post(props.apiUrlPrefix + "/getUser", params);
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
          const currentUser = res.data.body[0];

          if (currentUser.role.toLowerCase() === 'admin') {
            if (currentUser.status === true && currentUser.isdelete === false) {
              const user = await Auth.signIn(userDetail.email.toLowerCase(), userDetail.password);
              //console.log(user);
              if (user !== null) {
                if (user.attributes['custom:user_id'] !== null && Number(user.attributes['custom:user_id']) > 0) {

                }
                else {
                  await Auth.updateUserAttributes(user, {
                    'custom:user_id': currentUser.id.toString(),
                  });
                }
                //props.setAuthStatus(true);
                props.setUser(user);
                setLoading(false);
                props.history.push("/dashboard");
              }
            }
            else {
              currentValidation.validateId = 7;
              currentValidation.validateMessage = "Admin approval is pending";
              setValidation(currentValidation);
              setLoading(false);
            }
          }
          else {
            currentValidation.validateId = 6;
            currentValidation.validateMessage = "This email id is not Authorised.";
            setValidation(currentValidation);
            setLoading(false);
          }
        }
        else {
          currentValidation.validateId = 5;
          currentValidation.validateMessage = "This email id is not registered";
          setValidation(currentValidation);
          setLoading(false);
        }

      }
    } catch (error) {
      setLoading(false);
      if (error.code === 'NotAuthorizedException' || error.code === 'UserNotConfirmedException') {
        const currentValidation = {};
        currentValidation.validateId = 4;
        currentValidation.validateMessage = error.message;
        setValidation(currentValidation);
      }
      let err = null;
      !error.message ? err = { "message": error } : err = error;
    }

  }

  const handleSubmit = event => {
    event.preventDefault()
    if (isLoading) { return; }

    const validationStatus = validateForm();
    if (!validationStatus) {
      return false;
    }
    try {
      setLoading(true);
      submitLogin();

    } catch (error) {
      console.log(error.message);
    }
  }

  const handleChange = input => ({ target: { value } }) => {

    setUserDetail({
      ...userDetail,
      [input]: value
    })
  }
  return (
    <React.Fragment>
      <div className="mai-wrapper mai-login">
        <div className="main-content container-fluid">
          <div className="splash-container row " >
            <div className="col-md-12 login-form-main">
              <div className="row">
                <div className=" col-md-12 form-message">
                  <div className="login-content-container">
                    <div className="logo-top-space text center logo-center " >
                      <img src={tabnulogo} alt="zsah-white" className=" tabnu-logo" />
                    </div>
                    <div className="logo-text text center logo-center " >
                      <p className="text-center logo-tag-line">Log in to TabNu administration</p>
                    </div>
                    <form onSubmit={handleSubmit} className="login-form">
                      <FormGroup controlId="email" >
                        <div className="login-input-group">
                          <span className="input-icon-style">
                            <FontAwesomeIcon icon="user" />
                          </span>
                          <FormControl
                            autoFocus
                            type="email"
                            placeholder="Email"
                            value={userDetail.email} onChange={handleChange('email')}
                            className="login-input-field"
                          />
                        </div>
                        {validation.validateId === 1 && <span className="registration-validation">{validation.validateMessage}</span>}
                        {validation.validateId === 2 && <span className="registration-validation">{validation.validateMessage}</span>}
                      </FormGroup>
                      <FormGroup controlId="password" >
                        <div className="login-input-group">
                          <span className="input-icon-style">
                            <FontAwesomeIcon icon="lock" />
                          </span>
                          <FormControl
                            autoFocus
                            placeholder="Password"
                            type="password"
                            value={userDetail.password} onChange={handleChange('password')}
                            className="login-input-field"
                          />
                        </div>
                        {validation.validateId == 3 && <span className="registration-validation">{validation.validateMessage}</span>}
                      </FormGroup>

                      <div className="form-group row login-tools">

                        <div className="col-sm-12 pt-2 text-sm-right login-forgot-password">

                          <LinkContainer to="/resetpassword" className='forgot-password-style'>
                            <span>Forgot Password?</span>
                          </LinkContainer>
                        </div>
                      </div>
                      <div className="form-group login-submit login-submit1">
                        <Button block className="btn  btn-primary login-btns"
                          type="submit">
                          {!isLoading ? 'Login' : 'Please wait...'}
                        </Button>
                      </div>
                      <div style={{ marginTop: '15px' }}>
                        {validation.validateId == 4 && <span className="registration-validation">{validation.validateMessage}</span>}
                      </div>

                    </form>
                  </div>

                </div>
              </div>
              <div className="out-links footer-logo-center "><span>Powered by</span><br />

                <img src={zsahlogo} alt="zsah-white" className="login-footer-image" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login

