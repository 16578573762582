import React, { useState, useEffect, useCallback } from "react";
import Axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Assets/fontawesome';
import './userStyle.css'
import { Input } from "reactstrap"
import { Button, FormGroup, FormControl, Form } from "react-bootstrap";
import ReactS3 from 'react-s3'
import Config from '../../config'
import ScaleLoader from "react-spinners/ScaleLoader";

const imageConfig = {
  bucketName: Config.bucketName,//'tabnu-client-ui' ,//
  dirName: 'thumbnail', /* optional *///'Images',//
  region: 'eu-west-1',
  accessKeyId: Config.accesKey,//'AKIAJYS6QUY77JM5TVVQ',//
  secretAccessKey: Config.secretAccessKey,//'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',//
}

const User = (props) => {
  const [user, setUser] = useState({ id: 0, name: '', companyId: 0, email: '', designation: '', status: false, default_language: 1 });
  const [categories, setCategories] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [validation, setValidation] = useState({ validationId: 0, validationMessage: '' });
  const [isLoading, setLoading] = useState(false);

  // const loadCategories =async () => {
  //     await Axios.post(props.apiUrlPrefix + "/getCategories")
  //     .then((res) => {
  //         if(res!=null && res.data!=null && res.data.length>0 ){
  //             let lst = res.data;
  //              setCategories(lst);
  //         }
  //     })
  //     .catch(err => {  
  //         console.log(err) 
  //     });
  //   };
  const loadLanguages = async () => {
    setLoading(true);
    await Axios.post(props.apiUrlPrefix + "/getLanguages")
      .then((res) => {
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
          let lst = res.data.body;
          setLanguages(lst);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadCompanies = async () => {
    setLoading(true);
    await Axios.post(props.apiUrlPrefix + "/getOrganisationsAdmin", null, props.apiHeaderRequest)
      .then((res) => {
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
          let lst = res.data.body;
          setCompanies(lst);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const loadUser = useCallback((id) => {
    setLoading(true);
    Axios.post(props.apiUrlPrefix + "/getUsersAdmin", null, props.apiHeaderRequest)
      .then((res) => {
        if (res != null && res.data != null && res.data.statusCode === 200 && res.data.body.length > 0) {
          var lst = res.data.body;
          var filterUser = lst.find(x => x.id == id);
          if (filterUser) {
            const userInfo = {
              id: filterUser.id,
              name: filterUser.name,
              companyId: filterUser.usrcmp.id,
              email: filterUser.email.toLowerCase(),
              designation: filterUser.designation,
              status: filterUser.status,
              default_language: filterUser.default_language && filterUser.default_language > 0 ? filterUser.default_language : 1
            };
            setUser(userInfo);
          }

        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, [])

  const handleChange = input => ({ target: { value } }) => {
    setUser({
      ...user,
      [input]: value
    })
  }

  const handleRadioChange = input => ({ target: { value } }) => {
    setUser({
      ...user,
      [input]: value == "true" ? true : false
    })
  }

  const validateForm = () => {
    setValidation({ validationId: 0, validationMessage: '' });
    if (user.name.length <= 0) {
      setValidation({ validationId: 1, validationMessage: 'Enter the name' });
      return false;
    }
    else if (user.companyId <= 0) {
      setValidation({ validationId: 4, validationMessage: 'Please select company' });
      return false;
    }
    else if (user.default_language <= 0) {
      setValidation({ validationId: 2, validationMessage: 'Please select language' });
      return false;
    }
    else if (user.email.length <= 0) {
      setValidation({ validationId: 2, validationMessage: 'Enter the email' });
      return false;
    }
    else if (!ValidateEmail(user.email)) {
      setValidation({ validationId: 2, validationMessage: 'Email pattern is not correct' });
      return false;
    }
    else if (user.status === undefined) {
      setValidation({ validationId: 2, validationMessage: 'Please select the status.' });
      return false;
    }
    return true;
  }

  const ValidateEmail = (mail) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
      return true;
    }
    return false;
  }

  const handleSubmit = async event => {
    event.preventDefault();
    if (isLoading) { return; }

    const validationStatus = validateForm();
    if (!validationStatus) {
      return false;
    }
    try {
      setLoading(true);

      if (user.id > 0) {
        const params = {
          userId: user.id,
          name: user.name,
          status: user.status,
          designation: user.designation,
          orgId: parseInt(user.companyId),
          emailId: user.email.toLowerCase(),
          languageId: parseInt(user.default_language)

        };
        const res = await Axios.post(props.apiUrlPrefix + "/updateUserSettings", params, props.apiHeaderRequest);
        setLoading(false);
        if (res != null && res.data.statusCode === 200 && res.data.body.id > 0) {
          alert("Information saved");
          props.history.push("/userlist");
        }
        else if (res != null) {
          setValidation({ validationId: 6, validationMessage: res.data });
        }
      }
      else {
        const params = {
          password: 'Welcome@123',
          name: user.name,
          email: user.email.toLowerCase(),
          designation: user.designation,
          companyId: parseInt(user.companyId),
          defaultLanguage: parseInt(user.default_language),
          isSubscribe: false,
        }
        const res = await Axios.post(props.apiUrlPrefix + "/createUser", params);
        if (res != null && res.data !== null && res.data.statusCode === 200 && res.data.body.id > 0) {
          alert("Information saved");
          props.history.push("/userlist");
        }
        else if (res != null && res.data !== null) {
          setValidation({ validationId: 6, validationMessage: res.data });
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  }

  useEffect(async () => {
    //await loadCategories();
    await loadCompanies();
    await loadLanguages();
    const id = parseInt(props.match.params.id);// window.location.href.split('/')[window.location.href.split('/').length - 1];
    if (id > 0) {
      await loadUser(id);
    }
  }, []);

  return (
    <React.Fragment>
      <div className={isLoading ? 'loader-style' : ''}  >
        <ScaleLoader
          size={150}
          color={"white"}
          loading={isLoading}
        />
      </div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 ">
              <h2>User <small>create/update</small></h2>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="content-fluid">

          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit} className="user-form row">
                <FormGroup controlId="user.id" >
                  <div className="">
                    <FormControl
                      autoFocus
                      type="hidden"
                      value={user.id} onChange={handleChange('id')}
                    />
                  </div>
                </FormGroup>
                <div className="col-md-6">


                  <FormGroup controlId="user.name" >
                    <div className="user-input-group">
                      <span className="user-text-heads">
                        Name:
                      </span>
                      <FormControl
                        autoFocus
                        type="text"
                        placeholder="Name"
                        value={user.name} onChange={handleChange('name')}
                        className="user-input-field"
                      />
                    </div>
                    {/* {validation.validateId===1 && <span className="registration-validation">{validation.validateMessage}</span>} */}
                  </FormGroup>

                  <FormGroup controlId="user.email" >
                    <div className="user-input-group">
                      <span className="user-text-heads">
                        Email:
                      </span>
                      <FormControl
                        autoFocus
                        type="email"
                        disabled={user.id > 0}
                        placeholder="Email"
                        value={user.email.toLowerCase()}
                        onChange={handleChange('email')}
                        className="user-input-field"
                      />
                    </div>
                    {/* {validation.validateId===1 && <span className="registration-validation">{validation.validateMessage}</span>} */}
                  </FormGroup>

                  <FormGroup controlId="user.designation" >
                    <div className="user-input-group">
                      <span className="user-text-heads">
                        Role(optional):
                      </span>
                      <FormControl
                        autoFocus
                        type="text"
                        rows="5"
                        placeholder="Role(optional)"
                        value={user.designation} onChange={handleChange('designation')}
                        className="user-input-field"
                      />

                    </div>
                    {/* {validation.validateId===1 && <span className="registration-validation">{validation.validateMessage}</span>} */}
                  </FormGroup>

                </div>
                <div className="col-md-6">
                  <FormGroup controlId="user.company" >
                    <div className="user-input-group">
                      <span className="user-text-heads">
                        Select Company:
                      </span>
                      <Input
                        type="select"
                        id="data-company"
                        value={user.companyId}
                        className="user-input-field user-dropdown"
                        onChange={handleChange('companyId')}
                      >
                        <option value="0">Select Company</option>
                        {
                          companies && companies.map((data, index) => (
                            <option value={data.id} key={index}>{data.name}</option>
                          ))
                        }

                      </Input>
                    </div>
                    {/* {validation.validateId===1 && <span className="registration-validation">{validation.validateMessage}</span>} */}
                  </FormGroup>


                  <FormGroup controlId="user.language" >
                    <div className="user-input-group">
                      <span className="user-text-heads">
                        Select language:
                      </span>
                      <Input
                        type="select"
                        id="data-Institute"
                        value={user.default_language}
                        className="user-input-field user-dropdown"
                        onChange={handleChange('default_language')}
                      >
                        {
                          languages && languages.map((data, index) => (
                            <option value={data.id} key={index}>{data.name}</option>
                          ))
                        }

                      </Input>
                    </div>
                    {/* {validation.validateId==3 && <span className="registration-validation">{validation.validateMessage}</span>} */}
                  </FormGroup>

                  {user.id > 0 && <FormGroup controlId="user.status" >
                    <div className="user-input-group">
                      <span className="user-text-heads">
                        Status:
                      </span>
                      <br />
                      <input type="radio" name="groupStatus" className=""
                        value={true}
                        checked={user.status === true}
                        onChange={handleRadioChange('status')}
                      /> Enable
                      <input type="radio" name="groupStatus" className="ml-5"
                        value={false}
                        checked={user.status === false}
                        onChange={handleRadioChange('status')}
                      /> Disable

                    </div>
                    {validation.validateId === 3 && <span className="registration-validation">{validation.validateMessage}</span>}
                  </FormGroup>
                  }
                </div>

                <div className="col-md-12">
                  {validation.validationId > 0 && <span className="alert message-validation">{validation.validationMessage}</span>}
                  <hr className="user-seperator" />
                  <div className="form-group user-btn-submit ">

                    <a href="/userlist" className="ml-5"><span className="btn btn-secondary user-back-btn">Back</span></a>
                    <Button block className="btn  btn-info user-save-btn"
                      type="submit">
                      {!isLoading ? 'Save' : 'Please wait...'}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default User