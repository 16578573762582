// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
//import { faMoneyBill } from '@fortawesome/pro-solid-svg-icons';
import { faCode, faHighlighter ,faHome ,faArrowAltCircleRight, faCheckCircle,
faUser,faEnvelope,faLock,faBriefcase,faUserTag,faChevronLeft,faSquare,faKey,faPowerOff,faArrowDown,faLongArrowAltDown,
faChevronDown,faChevronUp,faStopwatch,faTimes,faGlobe,faDownload,faFileDownload,faLanguage,faSignLanguage,
faList,faBars,faTachometerAlt,faLayerGroup,faBox,faFilePdf, faCross,faTimesCircle, faEdit, faArchive
} from '@fortawesome/free-solid-svg-icons';

library.add(
  //faMoneyBill,
  faCode,
  faHighlighter,
  faHome,
  faArrowAltCircleRight,
  faCheckCircle,
  faUser,
  faEnvelope,
  faLock,
  faBriefcase,
  faUserTag,
  faChevronLeft,
  faSquare,
  faKey,
  faPowerOff,
  faArrowDown,
  faLongArrowAltDown,
  faChevronDown,
  faChevronUp,
  faStopwatch,
  faTimes,
  faGlobe,
  faDownload,
  faFileDownload,
  faLanguage,
  faSignLanguage,
  faList,
  faBars,faTachometerAlt,faLayerGroup,faFilePdf,faBox,faCross,faTimesCircle,faEdit,faArchive
  // more icons go here
);