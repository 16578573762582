import React from'react'
import footerLogo from "../../../Assets/Images/watermarkLogos.png"
const Footer=()=>{
    return (
<React.Fragment>
<footer className="main-footer">
    {/* <strong>Copyright &copy; 2014-2020 <a href="https://adminlte.io">AdminLTE.io</a>.</strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.1.0-rc
    </div> */}
    <div className="footer-right-section">
        <img src={footerLogo} alt="zsah-white" className="dashboard-footer-logo" />
        </div>
  </footer>
</React.Fragment>
    )

}
export default Footer