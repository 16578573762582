import { Auth } from 'aws-amplify';
import Axios from 'axios'
import Config from '../config'

const apiUrlPrefix =Config.apiUrlPrefix;
const documentPath =Config.documentUrl;
const iState={
    userDetail:{},
    isAuthenticated:false,
    dashboardList:[],
    contentList:[],
    content:{},
    groupList:[],
    group:{},
    userId:0,
    userName:'',
    isLogin:false,
    validationId:0,
    validationMessage:''

}

const checkEmail1 =async email=>{
    const currentValidation={};
      const status=false;
    try{
        const params = {
        emailId: email,
        };

        const res =await Axios.post(apiUrlPrefix + "/getUser",params)
            .catch(error => {  
              console.log(error.message) ;
              //setStatus(false);
              status=false;
          });
          //.then(res=>{
          if(res!=null && res.data!=null && res.data.length>0 ){
            const currentUser = res.data[0];
            
            if(currentUser.role!=='Admin'){
              if(currentUser.status===true && currentUser.isdelete===false){
               // setStatus(true);
                status=true;
              }
              else{
                
                // currentValidation.validateId=7;
                // currentValidation.validateMessage="Admin approval is pending";
                // setValidation(currentValidation);
                // setStatus(false);
                status=false;
              }
            }
            else{
            //   currentValidation.validateId=6;
            //   currentValidation.validateMessage="This email id is not Authorised.";
            //   setValidation(currentValidation);
            //   setStatus(false);
              status=false;
            }
          }
          else{
            // currentValidation.validateId=5;
            // currentValidation.validateMessage="This email id is not registered";
            // setValidation(currentValidation);
            // setStatus(false);
            status=false;
          }
        //})
        
    }
    catch(error){
      console.log(error.message);
    }
    return status;
  };

  const  loadSession=async (state)=>{
    const session = await Auth.currentSession();
    const user = await Auth.currentAuthenticatedUser();
    if(user){
        return{
            ...state,
            userId:user.attributes['custom:customer_id'],
            userName:user.attributes['userName'],
            isLogin:true ,
            isAuthenticated:true
        }
    }
  }

const reducer= (state=iState,action)=>{
    if(action.type==='USER_LOGIN'){
        const loginPayLoad = action.payload;
        const status=false;
        const params = {
            emailId: loginPayLoad.email,
            };
    
             Axios.post(apiUrlPrefix + "/getUser",params)
              .then( res=>{
              if(res!=null && res.data!=null && res.data.length>0 ){
                const currentUser = res.data[0];
                
                if(currentUser.role!=='Admin'){
                  if(currentUser.status===true && currentUser.isdelete===false){
                    const user = '';//await Auth.signIn(loginPayLoad.email, loginPayLoad.password);
                    if(user){
                        return {
                            ...state,
                            userId:parseInt(user.attributes['custom:user_id']),
                            userName:user.attributes['name'],
                            isLogin:true,
                            isAuthenticated:true
                        }
                    }
                  }
                  else{
                    
                    return {
                        ...state,
                        isAuthenticated:false,
                        validationId:7,
                        validationMessage:'Admin approval is pending'
                    }
                  }
                }
                else{
                return {
                    ...state,
                    isAuthenticated:false,
                    validationId:6,
                    validationMessage:'This email id is not Authorised.'
                }
                }
              }
              else{
                return {
                    ...state,
                    isAuthenticated:false,
                    validationId:5,
                    validationMessage:'This email id is not registered'
                }
              }
            })
            .catch(error => {  
                console.log(error.message) ;
            })
    }
    else if(action.type==='SET_LOGIN_SESSION'){
        loadSession(state);
    }
   // return state;
}

export default reducer;