import React, { Component } from "react";
import Axios from 'axios'
import Config from '../../config'
const apiUrlPrefix =Config.apiUrlPrefix;



const UserContainer = ({ users, loading,clickIgnore,clickApprove  }) => {
  if (loading) {
    return <h2>Loading...</h2>;
  }
//   const clickIgnore=(id)=>{
//     setLoading(true);
//     const params ={
//         userId:id
//     }
//     Axios.post(props.apiUrlPrefix + "/archiveUser",params)
//     .then((res) => {
//         if(res!=null && res.data!=null && res.data.id>0 ){
//          // loadPendingUsers();
//         }
//         setLoading(false);
//     })
//     .catch(err => {  
//         console.log(err) ;
//         //setLoading(false);
//     });
//     }
  
// const clickApprove=(id)=>{
//     setLoading(true);
//       const params={
//         userId:id
//       };
//       Axios.post(props.apiUrlPrefix + "/updateUserStatus",params)
//       .then((res) => {
//           if(res!=null && res.data!=null && res.data.id>0 ){
//             loadPendingUsers();
//           }
//           setLoading(false);
//       })
//       .catch(err => {  
//           console.log(err) ;
//           setLoading(false);
//       });
//     }

  return (
    <ul className='list-group mb-4'>
      {users && users.map(data => (
          <div className="col-md-12" >
          <div className="col-md-5 dashboard-block row">
              <div className="col-md-12"><h3>{data.name}</h3></div>
              <div className="col-md-9">
                  <div>{data.email}</div>
                  <div>{data.designation}</div>
                  <div>{data.company_name}</div>
              </div>
              <div className="col-md-3 text-right">
              {new Date(data.createdAt).getUTCDate() + '-' + (new Date(data.createdAt).getUTCMonth() + 1) + '-' + new Date(data.createdAt).getUTCFullYear()}
              </div>
              

              
              <div className="col-md-12">
              <div className="card-footer clearfix">
          <span className="btn btn-sm btn-danger float-left  " onClick={()=>clickIgnore(data.id)} >Ignore</span>
              <span className="btn btn-sm btn-info float-right "  onClick={()=>clickApprove(data.id)}   >Approve</span>
              </div>
              </div>
          </div>
         
      </div>

      ))}
    </ul>
  );
};

export default UserContainer;