import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Config from './config'
import Amplify from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import {createStore} from 'redux'
import {Provider} from 'react-redux'
import rootReducer from './Services/reducer'
const store=createStore(rootReducer)

console.log('REACT_APP_TEST_LINK: '+process.env.REACT_APP_TEST_LINK);
Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: Config.cognito.REGION,
		userPoolId: Config.cognito.USER_POOL_ID,
		identityPoolId: Config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: Config.cognito.APP_CLIENT_ID
	},
	API: {
		endpoints: [
			{
				name: 'testApiCall',
				endpoint: Config.apiGateway.URL,
				region: Config.apiGateway.REGION
			}
		]
	}
});

ReactDOM.render(
	<Provider store={store}>
		<Router>
			<App />
		</Router>
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
