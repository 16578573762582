import React from 'react';
import {  Switch } from 'react-router-dom';
import AppliedRoute from './Directives/Route/appliedRoute';
import Login from './Components/Login/login';
import ResetPassword from './Components/ResetPassword/resetPassword';
import Dashboard from './Components/Dashboard/dashboard';
 import ContentList from './Components/Content/contentList';
 import Content from './Components/Content/content';
import GroupList from './Components/Group/groupList';
 import Group from './Components/Group/group';
 import UserList from './Components/User/userList';
 import User from './Components/User/user';
 import OrganisationList from './Components/Organisation/organisationList';
 import Organisation from './Components/Organisation/organisation2';

export default ({ childProps }) => (
	<Switch>
		{childProps.isAuthenticated?
		(
			<React.Fragment>
			<AppliedRoute path="/" exact component={Dashboard} props={childProps} />
			<AppliedRoute path="/dashboard" exact component={Dashboard} props={childProps} />
			<AppliedRoute path="/grouplist" exact component={GroupList} props={childProps} />
			<AppliedRoute path="/group/:id" exact component={Group} props={childProps} />
			<AppliedRoute path="/group" exact component={Group} props={childProps} />
			<AppliedRoute path="/contentlist" exact component={ContentList} props={childProps} />
			<AppliedRoute path="/content/:videoId/:groupId" exact component={Content} props={childProps} />
			<AppliedRoute path="/content" exact component={Content} props={childProps} />
			<AppliedRoute path="/userlist" exact component={UserList} props={childProps} />
			<AppliedRoute path="/user/:id" exact component={User} props={childProps} />
			<AppliedRoute path="/user" exact component={User} props={childProps} />
			<AppliedRoute path="/organisationlist" exact component={OrganisationList} props={childProps} />
			<AppliedRoute path="/organisation/:id" exact component={Organisation} props={childProps} />
			<AppliedRoute path="/organisation" exact component={Organisation} props={childProps} />
			<AppliedRoute path="/resetpassword" exact component={ResetPassword} props={childProps} />
			</React.Fragment>
		):
		(
			<React.Fragment>
			<AppliedRoute path="/" exact component={Login} props={childProps} />
			<AppliedRoute path="/Login" exact component={Login} props={childProps} />
			<AppliedRoute path="/resetpassword" exact component={ResetPassword} props={childProps} />
			</React.Fragment>
		)
		}
			
			{/* <AppliedRoute path="/login" exact component={Login} props={childProps} />
			<AppliedRoute path="/dashboard" exact component={Dashboard} props={childProps} />
			<AppliedRoute path="/grouplist" exact component={GroupList} props={childProps} />
			<AppliedRoute path="/group/:id" exact component={Group} props={childProps} />
			<AppliedRoute path="/contentlist" exact component={ContentList} props={childProps} />
			<AppliedRoute path="/content/:id" exact component={Content} props={childProps} /> */}
		{/* Finally, catch all unmatched routes */}
		{/* <Route component={NotFound} /> */}
	</Switch>
);
