import React from'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../../Assets/fontawesome';
import './headerStyle.css'
import tabnulogo from "../../../Assets/Images/tabnuTempLogo.png"
const Header=(props)=>{
    return (
<React.Fragment>
<nav className="main-header navbar navbar-expand  navbar-dark">
  {/* Left navbar links */}
  <ul className="navbar-nav">
    <li className="nav-item">
      <a className="nav-link" data-widget="pushmenu" href="#" role="button">
      <FontAwesomeIcon icon="bars" size="1x" color="white"/>
          </a>
    </li>
    <li className="nav-item">
    <a href="/dashboard">
                  <span className="text-center">
                  <img src={tabnulogo} alt="zsah-white" className="logo-icon-layout" />
                  </span>
                  </a>
    </li>
    {/* <li className="nav-item d-none d-sm-inline-block">
      <a href="index3.html" className="nav-link">Home</a>
    </li>
    <li className="nav-item d-none d-sm-inline-block">
      <a href="#" className="nav-link">Contact</a>
    </li> */}
  </ul>
  {/* SEARCH FORM */}
  {/* <form className="form-inline ml-3">
    <div className="input-group input-group-sm">
      <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
      <div className="input-group-append">
        <button className="btn btn-navbar" type="submit">
          <i className="fas fa-search" />
        </button>
      </div>
    </div>
  </form> */}
  {/* Right navbar links */}
  <ul className="navbar-nav ml-auto">
    {/* Messages Dropdown Menu */}
    <li className="nav-item dropdown">
      <a className="nav-link" data-toggle="dropdown" href="#">
        
    <span className="user-name">{props.props.userName}</span> 
    <FontAwesomeIcon icon="chevron-down" size="1x" color="white"/>
      </a>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        
        <div className="dropdown-divider" />
        <a href="/resetpassword" className="dropdown-item">
        <FontAwesomeIcon icon="user"  /> Reset Password
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item" onClick={props.props.signOut}>
        <FontAwesomeIcon icon="power-off"  /> Log Out
        </a>
        <div className="dropdown-divider" />
        {/* <a href="#" className="dropdown-item dropdown-footer">See All Messages</a> */}
      </div>
    </li>
    {/* Notifications Dropdown Menu */}
    {/* <li className="nav-item dropdown">
      <a className="nav-link" data-toggle="dropdown" href="#">
        <i className="far fa-bell" />
        <span className="badge badge-warning navbar-badge">15</span>
      </a>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span className="dropdown-item dropdown-header">15 Notifications</span>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          <i className="fas fa-envelope mr-2" /> 4 new messages
          <span className="float-right text-muted text-sm">3 mins</span>
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          <i className="fas fa-users mr-2" /> 8 friend requests
          <span className="float-right text-muted text-sm">12 hours</span>
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          <i className="fas fa-file mr-2" /> 3 new reports
          <span className="float-right text-muted text-sm">2 days</span>
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
      </div>
    </li>
    <li className="nav-item">
      <a className="nav-link" data-widget="fullscreen" href="#" role="button">
        <i className="fas fa-expand-arrows-alt" />
      </a>
    </li>
    <li className="nav-item">
      <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#" role="button">
        <i className="fas fa-th-large" />
      </a>
    </li> */}
  </ul>
</nav>

</React.Fragment>
    )

}
export default Header